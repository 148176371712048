import React, { useEffect, useState } from 'react';
// import ChatBot, { ChatBotProvider } from "react-chatbotify";
import ChatBot from "react-chatbotify";
import chatbotConfig from './chatbotConfig';
// import { Button } from 'react-chatbotify';
import { v4 as uuidv4} from 'uuid';
// import ChatLogger from './components/ChatLogger';


const MyChatBot = () => {
	const [sessionId, setSessionId] = useState(''); // State to store the user's IP address
	const [currentPath, setCurrentPath] = useState(''); // State to store the current directory or subpath
	let hasError = false;

	const generateSessionId = () => {
		const randomUUID = uuidv4();	// Generates a random UUID
		const timestamp = Date.now()
		return `session_${timestamp}_${randomUUID}`
	}

	// Tooltip hidden in mobiles
	useEffect(() => {
		
		const handleWidthMessage = (event) => {
			if (event.data.type === 'viewportWidth') {
				console.log(`⬇️ Received viewport width: ${event.data.width}px`);
				const chatTooltip = document.querySelector('.rcb-chat-tooltip');

				if (chatTooltip) {
					console.log('⭐️ chatTooltip found')
					if (event.data.width <= 480) {
						chatTooltip.classList.remove('rcb-tooltip-show');
						chatTooltip.classList.add('rcb-tooltip-hide');
						console.log('📱 Tooltip hidden on mobile (new)');
					} else {
						chatTooltip.classList.remove('rcb-tooltip-hide');
						chatTooltip.classList.add('rcb-tooltip-show');
						console.log('💻 Tooltip visible on desktop (new)');
					}
				} else {
					console.log('⚠️ chatTooltip not found!');
				}
			}
		};

		window.addEventListener('message', handleWidthMessage);

		return () => {
			window.removeEventListener("message", handleWidthMessage);
		};
		
	}, []);

	// Browser's localStorage for Session Management
	useEffect(() => {
		// Check if a session ID already exists in localStorage
		const existingSessionId = localStorage.getItem('sessionId');
		if (existingSessionId) {
			console.log(`* Session ID already exists: ${existingSessionId}`)
			setSessionId(existingSessionId)	// Use the existing session ID
		} else {
			// Generate a new session ID and store it in localStorage
			const newSessionId = generateSessionId();
			localStorage.setItem('sessionId', newSessionId);
			console.log(`* Session ID created: ${newSessionId}`)
			setSessionId(newSessionId);	// Set the new session ID
		}

	}, []);

	// Capture the current directory or subpath
    useEffect(() => {
        const path = window.location.pathname; // Get the current path from the URL
        console.log('Current Path:', path); // Log the path for debugging
        setCurrentPath(path); // Store the path in state
    }, []);

	// Changes the style based on the path
	useEffect(() => {
		const updateChatWindow = () => {
			const chatWindow = document.querySelector('.rcb-chat-window');

			if (chatWindow) {
				if (currentPath === '/gmail') {
					console.log('\t* Gmail assistant!');
					// chatWindow.style.setProperty('width', '800px', 'important');
					chatWindow.style.setProperty('margin', '0px', 'important');
					chatWindow.style.setProperty('width', '300px', 'important');
					chatWindow.style.setProperty('height', '480px', 'important');
					chatWindow.style.right = '0px';
					chatWindow.style.bottom = '0px';
				}
				else if (currentPath === '/galapagosinsiders') {
					// chatWindow.style.setProperty('width', '400px', 'important');
					chatWindow.style.setProperty('height', '400px', 'important');	
				}
				else if (currentPath === '/assistant') {
					chatWindow.style.setProperty('margin', '0px', 'important');
					// chatWindow.style.setProperty('width', 'auto', 'important');
				}
				else if (currentPath === '/assistantdev' || currentPath === '/usparks-full') {
					chatWindow.style.setProperty('height', '95%', 'important');
					chatWindow.style.setProperty('width', '98%', 'important');
				}
			} else {
				console.log('\t* No chatWindow! Trying again in 500ms...');
				setTimeout(updateChatWindow, 500);
			}
		};
		updateChatWindow();
	}, [currentPath]);

	// Styling
	useEffect(() => {
		const checkChatWindow = () => {
			const chatWindow = document.querySelector('.rcb-chatbot-global');
	
			if (chatWindow) {
				console.log("✅ Chat window found, setting up observer...");
	
				const parentOrigin = window.location.ancestorOrigins
					? window.location.ancestorOrigins[0]  // Works in modern browsers
					: document.referrer.split('/').slice(0, 3).join('/');  // Fallback
	
				// ✅ Send an immediate message with the current state
				if (chatWindow.classList.contains('rcb-window-open')) {
					console.log(`🔍 Initial state: Chat is OPEN`);
					window.parent.postMessage("chatOpened", parentOrigin);
				} else {
					console.log(`🔍 Initial state: Chat is CLOSED`);
					window.parent.postMessage("chatClosed", parentOrigin);
				}
	
				const observer = new MutationObserver(() => {
					if (chatWindow.classList.contains('rcb-window-open')) {
						console.log(`📨 Sending chatOpened message to ${parentOrigin}...`);
						window.parent.postMessage("chatOpened", parentOrigin);
					} else {
						console.log(`📨 Sending chatClosed message to ${parentOrigin}...`);
						window.parent.postMessage("chatClosed", parentOrigin);
					}
				});
	
				observer.observe(chatWindow, { attributes: true, attributeFilter: ["class"] });
	
				return () => observer.disconnect();
	
			} else {
				console.log("⏳ Waiting for chat window...");
				setTimeout(checkChatWindow, 500); // Retry after 500ms
			}
		};
	
		checkChatWindow(); // Start checking for the chat window
	}, []);

	// Generate chatbot settings based on the effectivePath
	const getChatBotSettings = (effectivePath) => {
		if (effectivePath === 'usparks-full') {
			effectivePath = 'usparks'
		}
		// Normalize effectivePath and remove leading/trailing slashes
		const normalizedPath = effectivePath.replace(/^\//, '').replace(/\/$/, '');
		// Use the settings for the effectivePath or default to 'assistant'
		return chatbotConfig[normalizedPath] || chatbotConfig['assistant'];
	};
	
	// Amalia Endpoint
	const call_amalia = async (params) => {
		console.log(`params: ${JSON.stringify(params, null, 2)}`);
		console.log(`sessionID: ${sessionId}`)
		console.log(`currentPath: ${currentPath}`)

		let effectivePath = currentPath;
		if (!currentPath || currentPath === '/')
		{
			effectivePath = 'coastlinetravel'
		} else if (currentPath === '/usparks-full') {
			effectivePath = '/usparks'
		}

		console.log(`effectivePath: ${effectivePath}`)
		const url_to_use = effectivePath === "/assistant"
			? `https://chats.mytrip.ai/assistant/chat`
			: `https://chats.mytrip.ai/${effectivePath}-assistant/chat`;

		console.log(`url to use: ${url_to_use}`)

		// Append the effectivePath to the sessionId
		const clientIdWithEffectivePath = `${sessionId || 'unknown_user_chatbotify'}-${effectivePath}`;
		console.log(`client_id about to send: ${clientIdWithEffectivePath}`);


		try {
			// const response = await fetch('https://chats.mytrip.ai/coastlinetravel-assistant/chat', {
			const response = await fetch(url_to_use, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					message: params.userInput, // dynamically use the user's input
					// client_id: 'testing_261120241143', // replace with a relevant client_id if needed
					client_id: clientIdWithEffectivePath, // replace with a relevant client_id if needed
					// flag: 1,
				}),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const data = await response.json();

			// Assuming the response has a field called `reply` for the chatbot's response
			if (data.response) {
				// Checks if the response was a list of the chat history
				if (Array.isArray(data.response)) {
					console.log(`Response is a list: ${data.response}`)
					
					data.response.forEach(async (messageObj) => {
						const { message, role } = messageObj;

						// Switch case for different roles
						switch (role) {
							case "user":
								await params.injectMessage(message, "user")
								break;
							case "assistant":
								await params.injectMessage(message);
								break;
							default:
								console.warn(`Unexpected role ${role}`, messageObj)
						}
					});

				} else {
					console.log(`Response is NOT a list: ${data.response}`)
					await params.injectMessage(data.response);
				}
			} else {
				throw new Error("Response did not include a reply field");
			}
		} catch (error) {
			console.error(error);
			await params.injectMessage("Unable to connect to the chat service. Please try again.");
			hasError = true;
		}
	};

	const getChatHistory = async (params) => {

		// await params.injectMessage("Testing CR7!");
		// await params.injectMessage("Saying hi for the user", "user");
		// await params.injectMessage("Testing CR7 2");
		// await params.injectMessage("Saying hi for the user 2", "user");
		params.userInput = "mytripgreeting";
		await call_amalia(params)
	}

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data && event.data.type === "resize") {
				console.log(`📨 Received new size: ${event.data.width}x${event.data.height}`);

				// Select chat window
				const chatWindow = document.querySelector(".rcb-chat-window");
				if (chatWindow) {
					chatWindow.style.setProperty("width", `${event.data.width}px`, "important");
					chatWindow.style.setProperty("height", `${event.data.height}px`, "important");
					chatWindow.style.setProperty("margin", `0px`, "important");

					// Base dimensions for scaling reference
					const baseWidth = 400;
					const baseHeight = 550;

					// Calculate the scale factor
					const scaleWidth = event.data.width / baseWidth;
					const scaleHeight = event.data.height / baseHeight;
					const scaleFactor = Math.min(scaleWidth, scaleHeight);	// Choose the smaller scale factor

					// Apply scaling to the chat window
					chatWindow.style.transform = `scale(${scaleFactor})`;
					chatWindow.style.transformOrigin = "top left";	// Keeps it aligned properly

					console.log(`🔍 Applied scale: ${scaleFactor}`);
				} else {
					console.log('⚠️ No Chat Window was found, unable to adjust zoom');
				}
			}
		};

		window.addEventListener("message", handleMessage);
		return () => window.removeEventListener("message", handleMessage);
	}, []);

	const effectivePath = currentPath.replace(/^\//, '').replace(/\/$/, '') || 'coastlinetravel';
	const chatbotSettings = getChatBotSettings(effectivePath);
	if (currentPath === '/usparks-full') {
		chatbotSettings.settings.header.buttons = [];
	}

	const flow={
		start: {
			// message: chatbotSettings.message, // "Hi, I'm the Coastline Travel AI Assistant. Can I help you find contacts for exquisite lodging and journeys from our top-shelf providers? Where would your clients like to go?",
			// message: async (params) => {
			// 	params.userInput = "mytripgreeting"
			// 	await call_amalia(params);
			// },
			message: async (params) => {
				await getChatHistory(params);
				
			},
			path: (params) => {
				hasError = false; 
				return "loop";
			},
			// isSensitive: true,
		},

		loop: {
			message: async (params) => {
				await call_amalia(params);
			},
			path: () => {
				if (hasError) {
					return "start"
				}
				return "loop"
			}
		}
	}
	
	


	return (
		<>
			{/* <ChatBotProvider>
				<ChatLogger/> */}
				<ChatBot 
					message
					settings = {chatbotSettings.settings} flow={flow}
				/>
			{/* </ChatBotProvider> */}
		</>
	);
};

export default MyChatBot;